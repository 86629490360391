exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contest-1-js": () => import("./../../../src/pages/contest1.js" /* webpackChunkName: "component---src-pages-contest-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-pandatile-js": () => import("./../../../src/pages/privacy-policy-pandatile.js" /* webpackChunkName: "component---src-pages-privacy-policy-pandatile-js" */),
  "component---src-pages-suggest-js": () => import("./../../../src/pages/suggest.js" /* webpackChunkName: "component---src-pages-suggest-js" */)
}

