import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"

function SEO({ description, lang, meta, title }) {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  return (
        <Helmet
          htmlAttributes={{lang}}
          title={`${title}` || 'Imaglze App - IOs & Android'}
          titleTemplate={`${title}`}
          meta={[
            {
              name: `description`,
              content: description || t('seo.description'),
            },
            {
              property: `og:title`,
              content: title  || t('seo.website_title'),
            },
            {
              property: `og:description`,
              content: t('seo.og_description'),
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `og:image`,
              content: `https://www.imagzle.com/app/social/twitter.jpg`,
            },
            {
              name: `twitter:card`,
              content: `app`,
            },
            {
              name: `twitter:creator`,
              content: t('seo.twitter_handler'),
            },
            {
              name: `twitter:id`,
              content: t('seo.twitter_handler'),
            },
            {
              name: `twitter:title`,
              content: title || t('seo.website_title'),
            },
            {
              name: `twitter:text:title`,
              content: title || t('seo.website_title'),
            },
            {
              name: `twitter:description`,
              content: t('seo.og_description'),
            },
            {
              name: `twitter:app:name:iphone`,
              content: 'Imagzle',
            },
            {
              name: `twitter:app:name:googleplay`,
              content: 'Imagzle',
            },
            {
              name: `twitter:app:id:googleplay`,
              content: 'com.imagzle',
            },
          ].concat(meta)}
        />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
